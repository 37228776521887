import React from "react"
import { motion } from "framer-motion"

const Hamburger = ({ isOpen }) => {
  // const isOpen = true
  const variant = isOpen ? "opened" : "closed"
  const transition = {
    type: "tween",
    duration: 0.8,
    ease: [0.76, 0, 0.24, 1],
  }
  const topBottom = {
    closed: {
      opacity: 1,
    },
    opened: {
      opacity: 0,
    },
  }
  const center = {
    closed: {
      opacity: 1,
    },
    opened: {
      opacity: 1,
      rotate: -45,
    },
  }
  const center2 = {
    closed: {
      opacity: 1,
    },
    opened: {
      opacity: 1,
      rotate: 45,
    },
  }

  return (
    <svg
      // width="43"
      // height="43"
      className="h-[33px] md:h-[43px]"
      viewBox="0 0 43 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <motion.line
        initial="closed"
        variants={topBottom}
        animate={variant}
        transition={transition}
        y1="1"
        x2="43"
        y2="1"
        stroke="#9E9E9E"
        strokeWidth="2"
      />
      <motion.line
        initial="closed"
        variants={topBottom}
        animate={variant}
        transition={transition}
        y1="42"
        x2="43"
        y2="42"
        stroke="#9E9E9E"
        strokeWidth="2"
      />
      <motion.line
        initial="closed"
        variants={center}
        animate={variant}
        transition={transition}
        y1="21.5"
        x2="43"
        y2="21.5"
        stroke="#9E9E9E"
        strokeWidth="2"
      />
      <motion.line
        initial="closed"
        variants={center2}
        animate={variant}
        transition={transition}
        y1="21.5"
        x2="43"
        y2="21.5"
        stroke="#9E9E9E"
        strokeWidth="2"
      />
    </svg>
  )
}

export default Hamburger
