import React, { useState, useEffect, useRef } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { AnimatePresence, motion } from "framer-motion"

import LoveArtLogo from "./loveArtLogo"

// const verbs = [
//   "commission",
//   "advise",
//   "display",
//   "consult",
//   "curate",
//   "acquire",
//   "valuate",
//   "collect",
//   "manage",
//   "install",
//   "catalogue",
//   "educate",
// ]

const IntroAnimation = ({ setShowIntro, isMobile }) => {
  const data = useStaticQuery(graphql`
    {
      sanityIntroWords {
        introAnimationWords
      }
    }
  `)
  const words = useRef([...data.sanityIntroWords.introAnimationWords, "_"])
  const [introItems, setIntroItems] = useState([])
  const [introState, setIntroState] = useState("stack")
  useEffect(() => {
    let timeoutId
    if (introItems.length < words.current.length) {
      timeoutId = setTimeout(() => {
        setIntroItems([...introItems, words.current[introItems.length]])
      }, 500)
    }
    return () => {
      clearTimeout(timeoutId)
    }
  }, [introItems])
  return (
    <motion.div
      exit={{
        opacity: 0,
        transition: {
          duration: 1,
        },
      }}
      className="fixed top-0 left-0 w-full h-full uppercase font-love p-[2vw] text-[15.5vw] leading-[0.9] text-love-grey bg-white z-50 cursor-pointer"
      //Skip intro click handler
      onClick={() => setShowIntro(false)}
    >
      <AnimatePresence mode="wait">
        {introState === "stack" && (
          <motion.ul
            key="verbs"
            exit={{ opacity: 0 }}
            className="absolute flex flex-col justify-end h-full top-[0.95em]"
          >
            <motion.li
              layout="position"
              className="w-[60vw] mb-[2.5vw]"
              transition={{
                // layout: { ease: [0.87, 0, 0.13, 1] },
                layout: { ease: "easeInOut" },
              }}
            >
              <svg
                viewBox="0 0 231 46"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="LoveArtSVG">
                  <g id="Love">
                    <path
                      id="L"
                      d="M0.781738 44.0002H27.7335V41.7192H3.42289V1.1416H0.781738V44.0002Z"
                      fill="#C9C9C9"
                    />
                    <path
                      id="O"
                      d="M63.8227 22.5713C63.8227 10.0259 56.2594 0.121582 43.4738 0.121582C30.6282 0.121582 23.0649 10.0259 23.0649 22.5713C23.0649 35.1168 30.6282 45.0211 43.4738 45.0211C56.2594 45.0211 63.8227 35.1168 63.8227 22.5713ZM25.7061 22.5713C25.7061 11.5866 32.2489 2.40257 43.4738 2.40257C54.6387 2.40257 61.1815 11.5866 61.1815 22.5713C61.1815 33.5561 54.6387 42.7401 43.4738 42.7401C32.2489 42.7401 25.7061 33.5561 25.7061 22.5713Z"
                      fill="#C9C9C9"
                    />
                    <path
                      id="V"
                      d="M63.373 1.14209H60.6118L76.5788 44.0007H79.6401L95.6671 1.14209H93.0259L78.1995 41.7197H78.0794L63.373 1.14209Z"
                      fill="#C9C9C9"
                    />
                    <path
                      id="E"
                      d="M99.5137 41.7197V22.8715H124.124V20.5905H99.5137V3.42308H125.685V1.14209H96.8726V44.0007H125.985V41.7197H99.5137Z"
                      fill="#C9C9C9"
                    />
                  </g>
                  <g id="Art">
                    <path
                      id="A"
                      d="M144.688 1.14209L127.46 44.0007H130.282L135.744 30.2547H156.273L161.675 44.0007H164.497L147.569 1.14209H144.688ZM136.584 27.9738L146.129 3.72321H146.249L155.433 27.9738H136.584Z"
                      fill="black"
                    />
                    <path
                      id="R"
                      d="M184.613 22.1512H169.427V3.30303H184.613C192.777 3.30303 195.658 6.96462 195.658 12.0668C195.658 19.2099 191.396 22.1512 184.613 22.1512ZM187.254 24.3722C187.254 24.3722 198.179 23.7119 198.179 12.0668C198.179 3.90329 192.057 1.14209 184.613 1.14209H166.666V44.0007H169.427V24.4922H184.793L195.898 44.0007H198.719L187.254 24.3722Z"
                      fill="black"
                    />
                    <path
                      id="T"
                      d="M212.533 3.42308V44.0007H215.174V3.42308H230.301V1.14209H197.466V3.42308H212.533Z"
                      fill="black"
                    />
                  </g>
                </g>
              </svg>
            </motion.li>
            {introItems.map((item, i) => (
              <motion.li
                layout="position"
                key={item}
                className={`${i % 2 === 0 ? "text-black" : ""}`}
                transition={{
                  // layout: { ease: [0.87, 0, 0.13, 1] },
                  layout: { ease: "easeInOut" },
                }}
                onLayoutAnimationComplete={() => {
                  if (i === words.current.length - 2) {
                    setIntroState("love")
                  }
                }}
              >
                {item}
              </motion.li>
            ))}
          </motion.ul>
        )}
        {(introState === "love" ||
          introState === "loveart" ||
          introState === "shrink" ||
          introState === "top") && (
          <motion.div
            className="absolute bottom-[2vw]"
            initial={{ width: "60vw" }}
            animate={introState}
            variants={loveartVariants}
            custom={isMobile}
            transition={{
              delay: 0.3,
              duration: 0.6,
              ease: [0.87, 0, 0.13, 1],
            }}
            onAnimationComplete={variant => {
              variant === "shrink" && setIntroState("top")
              variant === "top" && setShowIntro(false)
            }}
          >
            <svg
              viewBox="0 0 231 46"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="LoveArtSVG">
                <motion.g
                  id="Love"
                  initial={{ opacity: 0, y: "100%" }}
                  animate={{ opacity: 1, y: "0%" }}
                  transition={{
                    delay: 0.3,
                    duration: 0.5,
                    ease: "easeInOut",
                  }}
                  onAnimationComplete={() => {
                    setIntroState("loveart")
                    console.log("love animation complete")
                  }}
                >
                  <path
                    id="L"
                    d="M0.781738 44.0002H27.7335V41.7192H3.42289V1.1416H0.781738V44.0002Z"
                    fill="#C9C9C9"
                  />
                  <path
                    id="O"
                    d="M63.8227 22.5713C63.8227 10.0259 56.2594 0.121582 43.4738 0.121582C30.6282 0.121582 23.0649 10.0259 23.0649 22.5713C23.0649 35.1168 30.6282 45.0211 43.4738 45.0211C56.2594 45.0211 63.8227 35.1168 63.8227 22.5713ZM25.7061 22.5713C25.7061 11.5866 32.2489 2.40257 43.4738 2.40257C54.6387 2.40257 61.1815 11.5866 61.1815 22.5713C61.1815 33.5561 54.6387 42.7401 43.4738 42.7401C32.2489 42.7401 25.7061 33.5561 25.7061 22.5713Z"
                    fill="#C9C9C9"
                  />
                  <path
                    id="V"
                    d="M63.373 1.14209H60.6118L76.5788 44.0007H79.6401L95.6671 1.14209H93.0259L78.1995 41.7197H78.0794L63.373 1.14209Z"
                    fill="#C9C9C9"
                  />
                  <path
                    id="E"
                    d="M99.5137 41.7197V22.8715H124.124V20.5905H99.5137V3.42308H125.685V1.14209H96.8726V44.0007H125.985V41.7197H99.5137Z"
                    fill="#C9C9C9"
                  />
                </motion.g>
                {(introState === "loveart" ||
                  introState === "shrink" ||
                  introState === "top") && (
                  <motion.g
                    id="Art"
                    initial={{ opacity: 0, y: "100%" }}
                    animate={{ opacity: 1, y: "0%" }}
                    transition={{
                      delay: 0.2,
                      duration: 0.5,
                      ease: "easeInOut",
                    }}
                    onAnimationComplete={() => {
                      setIntroState("shrink")
                      console.log("art animation complete")
                    }}
                  >
                    <path
                      id="A"
                      d="M144.688 1.14209L127.46 44.0007H130.282L135.744 30.2547H156.273L161.675 44.0007H164.497L147.569 1.14209H144.688ZM136.584 27.9738L146.129 3.72321H146.249L155.433 27.9738H136.584Z"
                      fill="black"
                    />
                    <path
                      id="R"
                      d="M184.613 22.1512H169.427V3.30303H184.613C192.777 3.30303 195.658 6.96462 195.658 12.0668C195.658 19.2099 191.396 22.1512 184.613 22.1512ZM187.254 24.3722C187.254 24.3722 198.179 23.7119 198.179 12.0668C198.179 3.90329 192.057 1.14209 184.613 1.14209H166.666V44.0007H169.427V24.4922H184.793L195.898 44.0007H198.719L187.254 24.3722Z"
                      fill="black"
                    />
                    <path
                      id="T"
                      d="M212.533 3.42308V44.0007H215.174V3.42308H230.301V1.14209H197.466V3.42308H212.533Z"
                      fill="black"
                    />
                  </motion.g>
                )}
              </g>
            </svg>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  )
}

export default IntroAnimation

const loveartVariants = {
  shrink: isMobile => ({
    width: isMobile ? "165.5px" : "231px",
  }),
  top: isMobile => ({
    width: isMobile ? "165.5px" : "231px",
    top: "1.4rem",
    left: "1.4rem",
    transition: {
      delay: 0.3,
      duration: 1.3,
      ease: [0.87, 0, 0.13, 1],
    },
  }),
  fade: {
    opacity: 0,
  },
}
