import React, { useState, useLayoutEffect } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { useMediaQuery } from "react-responsive"

//components
import IntroAnimation from "../components/introAnimation"
import MainMenu2 from "../components/mainMenu2"
import LoveArtLogo from "../components/loveArtLogo"
import HomeCarousel from "../components/homeCarousel"
import Hamburger from "../components/hamburger"
import { AnimatePresence } from "framer-motion"

const IndexPage = ({ location, data }) => {
  const [menuState, setMenuState] = useState(
    location?.state?.referrer ? "open" : "closed"
  )
  const [from, setFrom] = useState(
    location?.state?.referrer?.split("/").filter(item => item)[0]
  )
  const isMobile = useMediaQuery({
    query: "(max-width: 768px)",
  })

  //Persist introState in session storage
  const [showIntro, setShowIntro] = useStickyState(true, "showIntro")
  //Session storage rehydration stuff
  const [hasMounted, setHasMounted] = React.useState(false)
  useLayoutEffect(() => {
    setHasMounted(true)
  }, [])

  return (
    <Layout>
      <AnimatePresence>
        {showIntro && hasMounted && (
          <IntroAnimation setShowIntro={setShowIntro} isMobile={isMobile} />
        )}
      </AnimatePresence>
      {/* index content wrapper */}
      <div
        className="absolute inset-0 w-full h-full p-lead-1"
        //This is to stop rehydration flicker on initial load before showing animation
        style={{ opacity: !hasMounted ? 0 : 1 }}
      >
        <MainMenu2
          menuState={menuState}
          setMenuState={setMenuState}
          from={from}
        />
        <button
          className="fixed z-40 right-lead-1 top-lead-1"
          onClick={() => setMenuState("open")}
        >
          <Hamburger
            isOpen={(menuState === "open" || menuState === "page") && isMobile}
          />
        </button>
        <div className="flex flex-col h-[20vw]">
          <div className="">
            <LoveArtLogo />
          </div>
          <HomeCarousel carouselItems={data.sanityHomePage.indexCarousel} />
        </div>
      </div>
    </Layout>
  )
}

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="Home" />

export default IndexPage

export const query = graphql`
  query {
    sanityHomePage {
      indexCarousel {
        featureImage {
          asset {
            gatsbyImageData
            metadata {
              dimensions {
                aspectRatio
              }
            }
            id
          }
          altText
        }
        link
        _rawCaption
      }
    }
  }
`

//localstorage hook
function useStickyState(defaultValue, key) {
  const [value, setValue] = React.useState(() => {
    const stickyValue =
      typeof window === "undefined" ? null : window.sessionStorage.getItem(key)
    return stickyValue !== null ? JSON.parse(stickyValue) : defaultValue
  })
  React.useEffect(() => {
    window.sessionStorage.setItem(key, JSON.stringify(value))
  }, [key, value])
  return [value, setValue]
}
