import React, { Fragment, useState, useReducer } from "react"
import { motion, AnimatePresence, LayoutGroup } from "framer-motion"
import { navigate } from "@reach/router"
import { useMediaQuery } from "react-responsive"
import { PortableText } from "@portabletext/react"
import { useStaticQuery, graphql } from "gatsby"

// Components
import Cross from "./cross"

//placeholder items
const menuItemsArray = [
  {
    menuTitle: "ABOUT",
    url: "about",
  },
  {
    menuTitle: "SERVICES",
    url: "services",
  },
  {
    menuTitle: "PROJECTS",
    url: "projects",
  },
  {
    menuTitle: "LOVE[f]ART",
    url: "lovefart",
  },
  {
    menuTitle: "NEWS",
    url: "news",
  },
  {
    menuTitle: "CONTACT",
    url: "contact",
  },
  {
    menuTitle: "LOGIN",
    url: "login",
  },
]

const MainMenu2 = ({ menuState, setMenuState, from }) => {
  const [selectedItem, setSelectedItem] = useState(null)
  const [menuItems, setMenuItems] = useState(
    from ? menuItemsArray.filter(item => item.url === from) : menuItemsArray
  )
  const isMobile = useMediaQuery({
    query: "(max-width: 768px)",
  })
  const data = useStaticQuery(graphql`
    query menuQuery {
      sanityMenuFooter {
        _rawFirstColumn
        _rawSecondColumn
        _rawThirdColumn
      }
    }
  `)
  //Use useCallback for this?
  const filterMenuItems = clickedItem => {
    setSelectedItem(clickedItem.url)
    setMenuItems(menuItems.filter(item => item === clickedItem))
  }
  return (
    <Fragment>
      {/* overlay */}
      <AnimatePresence>
        {(menuState === "open" || menuState === "page") && (
          <motion.div
            initial={"closed"}
            animate={menuState}
            exit={"closed"}
            variants={overlayVariants}
            className="absolute inset-0 z-50 cursor-pointer md:bg-white"
            onClick={() => setMenuState("closed")}
          ></motion.div>
        )}
      </AnimatePresence>
      <motion.div
        className="fixed inset-0 z-50 flex flex-col justify-between pb-[calc(5rem+1.4rem)] md:pb-lead-1 bg-white p-lead-1 shadow-page-shadow md:shadow-side-modal md:grid md:grid-cols-12 md:gap-lead-1"
        initial={from ? "page" : "closed"}
        animate={menuState}
        custom={isMobile}
        variants={isMobile ? mobileMenuVariants : desktopMenuVariants}
        transition={menuTransition}
        onAnimationComplete={animation => {
          if (animation === "open" && from) {
            console.log(animation)
            setMenuItems(menuItemsArray)
          } else if (animation === "page") {
            navigate(`/${selectedItem}`)
          }
        }}
      >
        {/* menu items */}
        <LayoutGroup>
          <motion.ul
            className="md:col-span-3 md:col-start-1 h-max md:mt-[-0.6rem]"
            layout="size"
            onLayoutAnimationComplete={() => {
              console.log("layout animation complete")
              if (selectedItem) {
                setMenuState("page")
              }
            }}
          >
            <AnimatePresence>
              {menuItems.map((item, i) => (
                <motion.li
                  key={item.menuTitle}
                  className={`font-thin w-max text-mobile-large md:text-desktop-large leading-large text-love-black md:hover:text-love-grey ${
                    item.menuTitle === "LOVE[f]ART" ? "font-thinItalic" : ""
                  }`}
                  initial={{ opacity: item.url === from ? 1 : 0 }}
                  animate={{
                    opacity: 1,
                    transition: { opacity: { delay: 0.5 } },
                  }}
                  exit={{ opacity: 0 }}
                  layout="position"
                >
                  {item.menuTitle === "LOGIN" ? (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://clients.loveart.com/wp/contact/"
                    >
                      CLIENT&nbsp;LOGIN
                    </a>
                  ) : (
                    <button className="" onClick={() => filterMenuItems(item)}>
                      {item.menuTitle}
                    </button>
                  )}
                </motion.li>
              ))}
            </AnimatePresence>
          </motion.ul>
        </LayoutGroup>
        {/* Cross */}
        <motion.div
          variants={footerChildVariants}
          className="fixed justify-end hidden col-span-1 col-start-9 top-lead-1 right-lead-1 md:right-0 md:flex"
        >
          <button onClick={() => setMenuState("closed")}>
            <Cross />
          </button>
        </motion.div>
        {/* menu footer */}
        <motion.div
          variants={footerContainerVariants}
          className="col-span-9 col-start-1 md:grid md:grid-cols-9 gap-lead-1 text-love-grey md:absolute md:bottom-lead-1"
        >
          <motion.div
            variants={footerChildVariants}
            className="items-end col-span-3 col-start-1 md:flex"
          >
            <PortableText value={data.sanityMenuFooter._rawFirstColumn} />
          </motion.div>
          <motion.div
            variants={footerChildVariants}
            className="items-end hidden col-span-2 col-start-5 md:flex"
          >
            <PortableText value={data.sanityMenuFooter._rawSecondColumn} />
          </motion.div>
          <motion.div
            variants={footerChildVariants}
            className="items-end hidden col-span-2 col-start-8 md:flex"
          >
            <PortableText value={data.sanityMenuFooter._rawThirdColumn} />
          </motion.div>
        </motion.div>
      </motion.div>
    </Fragment>
  )
}

export default MainMenu2

const overlayVariants = {
  closed: {
    opacity: 0,
  },
  open: {
    opacity: 0.75,
  },
  page: {
    opacity: 0.75,
  },
}

const menuItemsVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: { opacity: { delay: 0.5 } },
  },
}

const footerContainerVariants = {
  closed: {},
  open: {
    transition: {
      // staggerChildren: 0.3,
      // delayChildren: 0.6,
    },
  },
  page: {},
}

const footerChildVariants = {
  closed: {
    opacity: 0,
  },
  open: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.8,
      ease: "easeInOut",
    },
  },
  page: {
    opacity: 0,
  },
}

let mobileMenuVariants = {
  closed: {
    y: "100%",
    x: 0,
    opacity: 0,
    transition: {
      type: "tween",
      duration: 0.8,
      ease: [0.76, 0, 0.24, 1],
      opacity: {
        delay: 1,
        duration: 0,
      },
    },
  },
  open: {
    y: 70,
    x: 0,
    opacity: 1,
  },
  page: {
    y: 0,
    x: 0,
    opacity: 1,
  },
}

let desktopMenuVariants = {
  closed: {
    x: "100%",
    y: 0,
    opacity: 0,
    transition: {
      type: "tween",
      duration: 0.8,
      ease: [0.76, 0, 0.24, 1],
      opacity: {
        delay: 1,
        duration: 0,
      },
    },
  },
  open: {
    x: "25%",
    y: 0,
    opacity: 1,
  },
  page: {
    x: 0,
    y: 0,
    opacity: 1,
  },
}
// let menuVariants = {
//   closed: isMobile => {
//     return {
//       y: isMobile ? "1000px" : 0,
//       x: isMobile ? 0 : "100%",
//       opacity: 0,
//       transition: {
//         ...menuTransition,
//         opacity: {
//           delay: 1,
//           duration: 0,
//         },
//       },
//     }
//   },
//   open: isMobile => {
//     return {
//       y: isMobile ? "70px" : 0,
//       x: isMobile ? 0 : "25%",
//       opacity: 1,
//     }
//   },
//   page: {
//     y: 0,
//     x: 0,
//     opacity: 1,
//   },
// }

let menuTransition = {
  type: "tween",
  duration: 0.8,
  ease: [0.76, 0, 0.24, 1],
  opacity: {
    duration: 0,
  },
}
